import React from "react";
import { motion } from "framer-motion";
import { FaRocket, FaUsers, FaTools, FaCog } from "react-icons/fa";

const AboutUs = () => {
  return (
    <section
      id='home'
      className='flex flex-col md:flex-row items-center justify-center relative w-full  p-2'
    >
      {/* Left Side - Image Section */}
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className='w-full md:w-1/2 flex justify-center items-center p-2 md:mt-5'
      >
        <img
          src='https://media.licdn.com/dms/image/v2/D4E12AQGcm4woOR2Z3Q/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1729209052026?e=2147483647&v=beta&t=5YbcRYDZwudoXTwSYbI9tlOsQM2BO9BL4nVQFENd9HA'
          alt='Hero Image'
          className='w-auto h-[200px] md:h-[400px] rounded-xl shadow-lg'
        />
      </motion.div>

      {/* Right Side - Content Section */}
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className='flex flex-col items-start justify-center text-left w-full md:w-1/2'
      >
        <div className='mb-2'>
          <blockquote className='text-md lg:text-xl text-black font-semibold uppercase w-full text-justify'>
            We’re a leading software company specializing in innovative
            solutions that streamline business operations and drive digital
            transformation for organizations worldwide.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-3 text-justify'>
            # We pride ourselves on fostering an inclusive, innovative, and
            collaborative work environment.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-1 text-justify'>
            # Our mission is to empower businesses with scalable, user-friendly
            software.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-1 text-justify'>
            # To revolutionize industries with intelligent software that
            simplifies complex problems.
          </blockquote>
        </div>

        {/* Key Points with Icons */}
        <motion.div
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={listContainerVariants}
          className='mt-4 space-y-4'
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={listItemVariants}
              className='flex items-center space-x-2'
            >
              <feature.icon className={`text-${feature.color}-600`} />
              <span className='text-sm text-gray-700 font-medium'>
                {feature.text}
              </span>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

// Animation Variants
const listContainerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.2 },
  },
};

const listItemVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

// Features Data
const features = [
  {
    icon: FaRocket,
    color: "blue",
    text: "Innovative and cutting-edge solutions",
  },
  {
    icon: FaUsers,
    color: "green",
    text: "Collaborative and inclusive work culture",
  },
  {
    icon: FaTools,
    color: "yellow",
    text: "Tailored software to fit business needs",
  },
  {
    icon: FaCog,
    color: "red",
    text: "Seamless integration and automation",
  },
];

export default AboutUs;
