import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";

import axios from "axios";

import LandingPage from "./LandingPage/index";
import { BASE_URL } from "./Common/apiURL";

const DashBoard = () => {
  const location = useLocation();

  const authToken = localStorage.getItem("token");
  const [dashboardData, setDashboardData] = useState(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const fetchDashboardData = async () => {
    try {
      const UserId = user[0]?.UserID;
      const Depo = user[0]?.DpCode;
      const requestBody = { UserId, Depo };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/data`,
        requestBody,
        config
      );

      if (response.data.success) {
        setDashboardData(response.data.data);
      } else {
        console.log("Failed to fetch dashboard data");
      }
    } catch (err) {
      console.error(
        "Error fetching dashboard data:",
        err.response.data.success
      );
      if (
        err.response &&
        err.response.data &&
        err.response.data.success === false
      ) {
        alert("Your token has expired ! Please login again.");
      }
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDashboardData();
    }
  }, [authToken]);

  if (!authToken) {
    return (
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    );
  }

  return (
    <div className='flex flex-col min-h-screen'>
      <Header data={dashboardData} />
      <div className='flex flex-1 bg-[#FFFFFF]'>
        <Navbar />
      </div>
    </div>
  );
};

export default DashBoard;
