import React from "react";
import { motion } from "framer-motion"; // Import framer-motion

// Static data for the features
const featureData = [
  {
    title: "Purchase and Procurement",
    image:
      "https://png.pngtree.com/thumb_back/fh260/background/20230618/pngtree-gabonese-e-commerce-illustrated-in-3d-for-social-media-and-websites-image_3637232.jpg",
    description:
      "Automate the procurement process, manage vendor relationships, and optimize your purchasing strategies. With powerful features to track purchase orders, handle supplier communication, and ensure compliance, our system helps you reduce costs and improve operational efficiency in purchasing.",
    keypoints: [
      {
        icon: "🛒",
        text: "Vendor management: Build strong relationships with suppliers and track performance.",
      },
      {
        icon: "📈",
        text: "Track purchase orders: Gain visibility into the status of orders in real-time, ensuring timely delivery.",
      },
      {
        icon: "📑",
        text: "Automated approvals: Simplify the procurement process with automated purchase order approval workflows.",
      },
      {
        icon: "⚙️",
        text: "Seamless integration: Connect procurement processes with inventory and accounting for smoother operations.",
      },
      {
        icon: "💡",
        text: "Cost optimization: Leverage data-driven insights to reduce procurement costs and negotiate better terms.",
      },
    ],
  },
  {
    title: "Sales",
    image:
      "https://cdn.prod.website-files.com/5b7f24cc900973de13d7beb4/658faf25a87b2de8c418bdda_Net%20Sales%201.svg",
    description:
      "Streamline your entire sales process, from lead generation to closing, by automating workflows, improving customer management, and providing real-time insights. Our solution enhances your team's ability to manage customer relationships effectively, ensuring increased sales and customer satisfaction.",
    keypoints: [
      {
        icon: "💼",
        text: "Lead management: Efficiently track and nurture leads from first contact to conversion.",
      },
      {
        icon: "📦",
        text: "Order management: Automate order processing, reduce errors, and enhance fulfillment efficiency.",
      },
      {
        icon: "🔄",
        text: "Seamless integration with inventory: Real-time stock updates improve order accuracy and prevent stockouts.",
      },
      {
        icon: "💳",
        text: "Integrated payment processing: Streamline payment collection with secure, automated payment gateways.",
      },
      {
        icon: "📊",
        text: "Sales analytics: Track sales trends, forecast future performance, and make data-driven decisions.",
      },
    ],
  },
  {
    title: "Accounting",
    image:
      "https://png.pngtree.com/thumb_back/fh260/background/20230705/pngtree-adorable-3d-render-of-accounting-tools-on-a-blue-background-image_3790570.jpg",
    description:
      "Manage your financial transactions effortlessly with automated bookkeeping, tax filing, and real-time reporting features. Our accounting solution ensures accurate financial data, compliance with regulations, and enables you to make informed decisions to support your business growth.",
    keypoints: [
      {
        icon: "💵",
        text: "Track income and expenses: Gain a clear view of your financials with detailed transaction tracking.",
      },
      {
        icon: "📊",
        text: "Generate financial reports: Automatically generate P&L, balance sheets, and other critical financial reports.",
      },
      {
        icon: "💡",
        text: "Automated tax calculations: Save time and reduce errors with automated tax computations for accurate filings.",
      },
      {
        icon: "⚖️",
        text: "Compliance assurance: Ensure your business adheres to regional accounting and tax laws.",
      },
      {
        icon: "💳",
        text: "Payment gateway integration: Easily manage payments and reconcile accounts with built-in gateway support.",
      },
    ],
  },
  {
    title: "Inventory",
    image:
      "https://img.freepik.com/free-vector/warehouse-interior-with-cardboard-boxes-racks_107791-1120.jpg",
    description:
      "Efficiently manage your inventory with real-time updates, automated stock tracking, and robust reporting capabilities. Our system ensures that your stock levels are always optimized, minimizing both overstocking and stockouts, and providing accurate data for better decision-making.",
    keypoints: [
      {
        icon: "📦",
        text: "Real-time updates: Monitor stock levels continuously, ensuring accurate availability for order fulfillment.",
      },
      {
        icon: "⚙️",
        text: "Automated management: Automatically reorder products and track stock movements to avoid shortages.",
      },
      {
        icon: "🔄",
        text: "Integrated processes: Synchronize inventory management with sales and procurement for end-to-end control.",
      },
      {
        icon: "📈",
        text: "Performance tracking: Access in-depth insights into stock performance, turnover rates, and demand patterns.",
      },
      {
        icon: "🔔",
        text: "Low stock alerts: Set custom alerts for low stock levels, ensuring timely reordering and preventing sales delays.",
      },
    ],
  },
  {
    title: "Multi-Location",
    image:
      "https://media.licdn.com/dms/image/v2/D5612AQG5N6XuKCb16w/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1723247461982?e=2147483647&v=beta&t=M6RYL8OewrzQSPVL5oNyysPWvOvBBL451p5692F8aCU",
    description:
      "Manage your business across multiple locations seamlessly with real-time data synchronization. Our solution ensures centralized control over inventory, sales, and procurement, while enabling location-specific insights and reporting for improved decision-making and operational control.",
    keypoints: [
      {
        icon: "🌍",
        text: "Multi-location management: Centralize control over all your locations, from inventory to sales.",
      },
      {
        icon: "📦",
        text: "Track stock levels: Monitor and manage stock across all locations to prevent overstocking or shortages.",
      },
      {
        icon: "⚙️",
        text: "Centralized operations: Streamline procurement, sales, and inventory functions across different locations.",
      },
      {
        icon: "📊",
        text: "Location-specific insights: Gain reports and analytics tailored to each location for better decision-making.",
      },
      {
        icon: "🔄",
        text: "Real-time data sync: Ensure that all locations are updated simultaneously, improving operational efficiency.",
      },
    ],
  },
  {
    title: "Reporting and Analytics",
    image:
      "https://img-cdn.inc.com/image/upload/f_webp,q_auto,c_fit/images/panoramic/GettyImages-1253379369_531031_kkewpz.jpg",
    description:
      "Make data-driven decisions with comprehensive reporting and analytics capabilities. Our system provides customizable reports across all business functions, helping you track performance, identify opportunities, and stay ahead of industry trends to drive growth.",
    keypoints: [
      {
        icon: "📊",
        text: "Customizable reports: Create tailored reports for any department or function to meet your specific needs.",
      },
      {
        icon: "🔍",
        text: "In-depth analysis: Dive deep into your data to uncover trends, inefficiencies, and growth areas.",
      },
      {
        icon: "📈",
        text: "KPI tracking: Monitor key performance indicators across your organization to track progress toward goals.",
      },
      {
        icon: "💡",
        text: "Identify opportunities: Use data insights to discover new opportunities for revenue and efficiency gains.",
      },
      {
        icon: "⚙️",
        text: "Automated report generation: Save time with automated report scheduling and delivery to key stakeholders.",
      },
    ],
  },
];

const Features = () => {
  return (
    <section
      id='features'
      className='p-2 max-w-[90%] transition-all duration-700'
    >
      <h2 className='text-xl font-bold text-center text-black m-4'>
        Key Features of Cloud ERP Software
      </h2>

      {/* Features Grid */}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 '>
        {featureData.map((feature, index) => (
          <div
            key={index}
            whileInView={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.1 }}
            className='p-4 rounded-lg shadow-lg bg-gray-100 cursor-pointer transform hover:scale-95 hover:bg-white hover:shadow-2xl transition-transform duration-700'
          >
            <img
              src={feature.image}
              alt={feature.title}
              className='object-fit h-fit rounded-md mb-4'
            />
            <h3 className='text-xl font-semibold text-center text-black mb-2'>
              {feature.title}
            </h3>
            <p className='text-sm text-gray-600 text-justify mb-4'>
              {feature.description}
            </p>

            {/* Key Points */}
            <ul className='space-y-1'>
              {feature.keypoints.map((point, idx) => (
                <li
                  key={idx}
                  className='flex items-center gap-2 text-xs text-gray-700'
                >
                  <span className='text-2xl'>{point.icon}</span>
                  <span>{point.text}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
