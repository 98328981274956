import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FaChevronDown,
  FaChevronUp,
  FaLock,
  FaUserPlus,
  FaUserShield,
  FaSync,
} from "react-icons/fa";

// FAQ data
const faqs = [
  {
    question: "How long have you been in business?",
    answer:
      "We have been providing software development services for 18 years.",
    icon: <FaUserShield />, // Example icon for "business" category
  },
  {
    question: "How can I purchase the services?",
    answer:
      "You can purchase our services by contacting us at the provided number.",
    icon: <FaUserPlus />, // Icon for account setup
  },
  {
    question: "How can I try your software before buying?",
    answer: "We offer a free trial and demo services for our clients.",
    icon: <FaLock />, // Icon for security-related questions
  },
  {
    question: "What platforms does your software support?",
    answer: "Our software is compatible with phones and laptops.",
    icon: <FaSync />, // Icon for platform-related question
  },
];

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='flex flex-col items-center justify-center p-4 w-full'>
      <h2 className='text-xl font-bold text-center mb-5'>
        Frequently Asked Questions
      </h2>
      <div className='w-full bg-gray-100 shadow-lg rounded-lg overflow-hidden  md:max-w-[80%]'>
        {faqs.map((faq, index) => (
          <div key={index} className='border-b'>
            {/* Accordion Button with Motion Animation */}
            <motion.button
              onClick={() => toggleAccordion(index)}
              className='w-full flex justify-between items-center p-4 text-left text-md font-semibold text-gray-800 focus:outline-none'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className='flex items-center text-sm md:text-md'>
                <motion.span
                  className='mr-3 text-blue-500'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  {faq.icon}
                </motion.span>
                {faq.question}
              </div>
              {openIndex === index ? (
                <FaChevronUp className='text-blue-500' />
              ) : (
                <FaChevronDown className='text-blue-500' />
              )}
            </motion.button>

            {/* Accordion Content with Motion Animation */}
            {openIndex === index && (
              <motion.div
                className='p-1 text-gray-600 text-sm ml-10'
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                transition={{ duration: 0.3 }}
              >
                {faq.answer}
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQPage;
