import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className='bg-gray-200 text-black p-2 mt-auto shadow-2xl'>
      <div className='flex justify-between items-center'>
        {/* Left Side: Copyright */}
        <p className='text-xs'>
          &copy; {new Date().getFullYear()} SRS SOFTWARES. All rights reserved.
        </p>

        {/* Right Side: Social Media Icons */}
        <div className='flex space-x-4'>
          <a
            href='https://www.facebook.com/SRSSOFTWARESPTAC'
            target='_blank'
            rel='noopener noreferrer'
            className='hover:opacity-80'
          >
            <FaFacebook size={24} color='#4267B2' /> {/* Facebook Blue */}
          </a>
          <a
            href='https://x.com/i/flow/login?redirect_after_login=%2Fsrssoftwares'
            target='_blank'
            rel='noopener noreferrer'
            className='hover:opacity-80'
          >
            <FaTwitter size={24} color='#1DA1F2' /> {/* Twitter Blue */}
          </a>
          <a
            href='https://www.linkedin.com/company/srs-softwares/posts/?feedView=all'
            target='_blank'
            rel='noopener noreferrer'
            className='hover:opacity-80'
          >
            <FaLinkedin size={24} color='#0A66C2' /> {/* LinkedIn Blue */}
          </a>
          <a
            href='https://www.instagram.com/srssoftwares/'
            target='_blank'
            rel='noopener noreferrer'
            className='hover:opacity-80'
          >
            <FaInstagram size={24} color='#E1306C' /> {/* Instagram Pink */}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
