import React, { useState } from "react";
import axios from "axios";
import { FaUser, FaBuilding, FaPhoneAlt, FaEnvelope } from "react-icons/fa"; // Importing icons for the fields

const PricingPlans = () => {
  // State to hold form data
  const [formData, setFormData] = useState({
    companyName: "",
    name: "",
    email: "",
    message: "",
    phone: "", // Add phone number field to the state
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    sendWhatsAppMessage(); // Send WhatsApp message when form is submitted
    // Reset form if necessary
    setFormData({
      companyName: "",
      name: "",
      email: "",
      message: "",
      phone: "",
    });
  };

  // Function to send WhatsApp message using AutoSender API
  const sendWhatsAppMessage = async () => {
    const userName = "pranavspider"; // AutoSender username
    const password = "Pranav2201"; // AutoSender password
    const receiverPhoneNumber = "918869829800"; // Constant Number all msg on this number

    // Structure the message with form data (no icons since WhatsApp only supports text)
    const message = `
    Company Name: ${formData.companyName}\n
    User Name: ${formData.name}\n
    Phone: ${formData.phone}\n
    Message: ${formData.message}
  `;

    // Encode the message to ensure proper transmission
    const encodedMessage = encodeURIComponent(message);

    // API URL for AutoSender service
    const apiUrl = `https://app.messageautosender.com/api/v1/message/create?username=${userName}&password=${password}&receiverMobileNo=${receiverPhoneNumber}&message=${encodedMessage}`;
    const response = await axios.post(apiUrl);

    console.log("Message sent successfully:", response.data);
    alert("Message sent successfully to the receiver.");
    // try {
    //   // Sending the POST request using Axios
    // } catch (error) {
    //   // Handle errors properly and log them
    //   console.error(
    //     "Error sending message:",
    //     error.response ? error.response.data : error.message
    //   );
    //   alert("An error occurred while sending the message. Please try again.");
    // }
  };

  return (
    <section id='pricing' className='py-10 px-4 w-full'>
      <div className='mx-auto max-w-6xl grid grid-cols-1 lg:grid-cols-1 gap-8'>
        {/* Lead Generation Form */}
        <div className='bg-gray-100 p-6 rounded-lg shadow-lg'>
          <h3 className='text-xl font-semibold text-gray-800 mb-4'>
            Get a Quote for the Enterprise Plan
          </h3>
          <div className='flex flex-col items-center'>
            <form
              className='w-full shadow-md rounded px-8 pt-2 pb-2 mb-4'
              onSubmit={handleSubmit}
            >
              {/* Company Name */}
              <div className='mb-2'>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='companyName'
                >
                  Company Name
                </label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline'
                  id='companyName'
                  name='companyName'
                  type='text'
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder='Your Company Name'
                  required
                />
              </div>

              {/* Name */}
              <div className='mb-2'>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='name'
                >
                  Name
                </label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline'
                  id='name'
                  name='name'
                  type='text'
                  value={formData.name}
                  onChange={handleChange}
                  placeholder='Your Name'
                  required
                />
              </div>

              {/* Email */}
              <div className='mb-2'>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='phone'
                >
                  Phone
                </label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline'
                  id='phone'
                  name='phone'
                  type='tel'
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder='Your Phone'
                  required
                />
              </div>

              {/* Message */}
              <div className='mb-2'>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='message'
                >
                  Message
                </label>
                <textarea
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline'
                  id='message'
                  name='message'
                  rows='4'
                  value={formData.message}
                  onChange={handleChange}
                  placeholder='Your Message'
                  required
                ></textarea>
              </div>

              {/* Submit Button */}
              <button
                type='submit'
                className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full text-xs'
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;
