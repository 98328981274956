import React from "react";
import { motion } from "framer-motion";
import {
  FaRegThumbsUp,
  FaClock,
  FaCogs,
  FaHeadset,
  FaDollarSign,
  FaUsers,
} from "react-icons/fa";

const WhyChooseUs = () => {
  return (
    <section id='why-choose-us' className='py-12 bg-gray-50'>
      <div className='container mx-auto text-center px-6 lg:px-16'>
        <h2 className='text-3xl font-extrabold text-gray-800 mb-4'>
          Why Choose Us?
        </h2>
        <p className='text-md text-gray-600 max-w-3xl mx-auto mb-8 leading-relaxed'>
          We provide faster deployment, custom solutions, superior customer
          support, and more. Our team is skilled in the latest technologies and
          follows agile development practices to ensure flexibility, timely
          delivery, and high-quality results. From initial consultation to
          post-launch support, we’re with you every step of the way.
        </p>
      </div>

      <motion.div
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.3 }}
        className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-6 lg:px-16'
      >
        {/* Cards */}
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className='bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl hover:scale-90 transform transition-all duration-500   cursor-pointer hover'
            variants={cardVariants}
            custom={index}
          >
            <div className='flex justify-center mb-4'>
              <feature.icon size={40} className={`${feature.color} text-4xl`} />
            </div>
            <h3 className='text-lg font-semibold text-gray-800 mb-2'>
              {feature.title}
            </h3>
            <p className='text-gray-600 text-sm leading-relaxed'>
              {feature.description}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

// Animation Variants
const cardVariants = {
  hidden: { opacity: 0, y: 0 },
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.6, delay: index * 0.2 },
  }),
};

// Features Data
const features = [
  {
    icon: FaClock,
    color: "text-blue-500",
    title: "Faster Deployment",
    description:
      "We prioritize faster deployment so you can get your product to market quickly and efficiently.",
  },
  {
    icon: FaCogs,
    color: "text-green-500",
    title: "Custom Solutions",
    description:
      "We create tailored solutions that fit your unique business requirements, ensuring scalability and flexibility.",
  },
  {
    icon: FaHeadset,
    color: "text-purple-500",
    title: "Superior Customer Support",
    description:
      "Our team is available 24/7 to provide you with top-tier support, ensuring smooth operations and quick issue resolution.",
  },
  {
    icon: FaUsers,
    color: "text-yellow-500",
    title: "Agile Development",
    description:
      "We follow agile practices to ensure flexibility and adaptability, enabling us to respond quickly to any changes or new requirements.",
  },
  {
    icon: FaDollarSign,
    color: "text-green-500",
    title: "Transparent Pricing",
    description:
      "We provide clear and upfront pricing, so there are no hidden costs. You’ll know exactly what you’re paying for.",
  },
  {
    icon: FaRegThumbsUp,
    color: "text-indigo-500",
    title: "Timely Delivery",
    description:
      "Our team ensures on-time delivery, meeting your deadlines while maintaining the quality of the product.",
  },
];

export default WhyChooseUs;
